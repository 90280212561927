// Should polyfill for Chromium version
import 'core-js/stable';
import { createRoot } from 'react-dom/client';
import React from 'react';

import App from './App';
import './utils/sentry';

document.onreadystatechange = (): void => {
  if (document.readyState === 'interactive') {
    const root = createRoot(document.getElementById('template') as Element);
    root.render(<App />);
  }
};
